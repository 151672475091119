import React from "react"
import Layout from "../../components/layout"
import HeaderBanner from "../../components/common/headerBanner/headerBanner"
import CTABanner from "../../components/common/ctaBanner/ctaBanner"
import * as styles from "./business.module.scss"
import Usage from "../../components/sections/usage/usage"
import Breadcrumb from "../../components/common/breadcrumb/breadcrumb"
import { LearnMoreTagline } from "../../components/common/typography/banner-tagline"

const BusinessUsage = () => (
  <Layout>
    <HeaderBanner title="Business Usage" />
    <div className={styles.pageContainer}>
      <Breadcrumb
        firstLink="/ironcap-x"
        firstLinkTitle="IronCAP X"
        secondLink="/ironcap-x/business"
        secondLinkTitle="Business Usage"
      />
      <Usage usage="Business" />
    </div>
    <CTABanner tagline={LearnMoreTagline} CTA="video" CTADetail="how video" />
  </Layout>
)

export default BusinessUsage
